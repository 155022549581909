import React from "react"
import { Link } from "gatsby"

const Sr = () => {
  
  return(
    <div className="container my-5 pt-5">
      <p className="h3 my-4">
        BitTorrent is a TRON Super Representative
      </p>
      <p>
        BitTorrent is proud to be an active Super Representative, elected by the TRON community in 2018. As the company behind the original decentralized p2p communications protocol, we continue to offer our community safe torrenting software for <Link to="/downloads/windows/">Windows</Link>, <Link to="/downloads/mac/">Mac</Link> and <Link to="/downloads/android/">Android</Link>.  
      </p>
      <p>
        Our team has a deep understanding of decentralized technology and products, and we know that a decentralized internet needs a foundation where decentralized app development can flourish. By supporting the TRON platform, we help maintain the reliability and stability of the ongoing block generation, providing the backbone of the network to support thousands of 3rd party DApps and services running on TRON.
      </p>
      <p>
        With the collaboration between BitTorrent and TRON, we are confident that the internet will see a better and brighter future. We hope that all BitTorrent users will be able to share in this great revolution - offering more options for the internet and making it more democratic, free and decentralized. Lastly, we thank the TRON community for continuing to vote for BitTorrent as one of the 27 SRs!
      </p>

      <p className="h3 my-4">
        Why us?
      </p>
      <p>
        We run an extensive infrastructure to support our massive worldwide user base. We have servers housed in multiple state of the art data centers around the world with disaster recovery centers in Los Angeles and Iceland.      </p>
      <p>
        Available infrastructure for this project <br/>
        CPU 72 Cores, RAM 1TB, SSD: 3T <br/>
        Network: 25 gbs <br/>
        Available IT Budget: $120,000
      </p>
      <p>
        Interested in contacting us? Please send inquiries to sr |at| bittorrent |dot| com
      </p>
    </div>
  )
}


export default Sr


